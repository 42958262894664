<template>
    <div class="sector">
        <SanackBar
            :activated="snackActivated"
            :success="snackSuccess"
            :message="snackMessage"
            @closeSnackBar="closeSnack()" />

        <v-alert
            color="#f0f0f0"
            icon="mdi-alert-circle-outline"
            border="left"
            v-if="!countUsersLinked">
            <p>Você não possui alunos vinculados!</p>

            <v-btn
                title="Vincular"
                color="primary"
                elevation="0"
                rounded
                large
                dense
                @click="goTo()">
                <span>Vincular</span>
            </v-btn>
        </v-alert>

        <div class="form" v-else>
            <div class="form-head">
                <h1 class="head-title">{{ titles.sector }}</h1>
            </div>

            <div class="form-fields">
                <div class="field-box">
                    <label>Nome do grupo</label>
                    <v-text-field
                        v-model="group.name"
                        ref="name"
                        type="name"
                        filled
                        rounded
                        dense>
                    </v-text-field>
                </div>

                <div class="field-box">
                    <h2 class="box-title">Alunos</h2>
                    <DualListBox
                        :source="usersLinked"
                        :destination="usersDestination"
                        label="name"
                        @onChangeList="onChangeListUsers" />
                </div>

                <div class="field-box field-box-managers">
                    <h2 class="box-title">Profissionais</h2>
                    <DualListBox
                        :source="managersLinked"
                        :destination="managersDestination"
                        label="name"
                        @onChangeList="onChangeListManagers" />
                </div>

                <div class="box field-box-submit">
                    <SubmitFormButton
                        :title="titles.submitButton"
                        :disabled="formSubmited"
                        @submitForm="submitForm()" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SanackBar from '@/components/snack/SanackBar.vue';
    import DualListBox from '@/modules/company/components/DualListBox.vue';
    import SubmitFormButton from '@/components/SubmitFormButton.vue';

    export default {
        name: 'FormGroup',
        components: {
            SanackBar,
            DualListBox,
            SubmitFormButton
        },
        data() {
            return {
                snackActivated: false,
                snackSuccess: false,
                snackMessage: null,
                formSubmited: false,
                isAmbientLocalTests: false,
                usersLinked: [],
                usersDestination: [],
                managersLinked: [],
                managersDestination: [],
                countUsersLinked: null,
                group: {
                    id: null, // use for edit
                    name: null,
                    users: [] // array of ids
                },
                titles: {
                    sector: null,
                    submitButton: null
                }
            };
        },
        mounted() {
            this.geralLoading();

            this.checkIsEditionMode();
        },
        watch: {
            usersDestination: {
                handler(e) {
                    this.sincronizeUsers(e);
                },
                deep: true
            },
            managersDestination: {
                handler(e) {
                    this.sincronizeUsers(e);
                },
                deep: true
            }
        },
        computed: {
            ...mapGetters({
                groupStore: 'getDataGroup'
            })
        },
        methods: {
            activeSnack(success, message) {
                this.snackActivated = true;
                this.snackSuccess = success;
                this.snackMessage = message;
            },
            closeSnack() {
                this.snackActivated = false;
            },
            goTo() {
                return this.$router.push({ name: 'findUsersCompany' });
            },
            async checkIsEditionMode() {
                if (this.groupStore.isEditionMode) {
                    this.titles.sector = 'Editar grupo';
                    this.titles.submitButton = 'Atualizar';

                    await this.serviceLoadingGroup();
                } else {
                    this.titles.sector = 'Criar grupo';
                    this.titles.submitButton = 'Criar';
                }
            },
            dataGroupParse(data) {
                const { id, name, users } = data;

                this.group.id = id;
                this.group.name = name;
                this.group.users = users.map((e) => e.id);
            },
            dataGroupOrganize(data) {
                let index = null;

                data.users.forEach((e) => {
                    if (e.role === 'USER') {
                        index = this.usersLinked.findIndex((u) => u.id === e.id);

                        if (index !== -1) this.usersLinked.splice(index, 1);

                        this.usersDestination.push(e);
                    }

                    if (e.role === 'MANAGER') {
                        index = this.managersLinked.findIndex((u) => u.id === e.id);

                        if (index !== -1) this.managersLinked.splice(index, 1);

                        this.managersDestination.push(e);
                    }
                });
            },
            async serviceLoadingGroup() {
                this.$store.commit('setGlobalLoading', true);

                const groupId = this.groupStore.id;

                this.$service.http['company']
                    .getGroupById(groupId)
                    .then((result) => {
                        const record = result.data.doc;

                        this.dataGroupParse(record);

                        this.dataGroupOrganize(record);
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally((_) => {
                        this.$store.commit('setGlobalLoading', false);
                    });
            },
            dataUsersPopulate(data) {
                const usersLinked = [],
                    managersLinked = [];

                data.forEach((e) => {
                    if (e.role === 'USER') usersLinked.push(e);
                    if (e.role === 'MANAGER') managersLinked.push(e);
                });

                this.usersLinked = usersLinked;
                this.managersLinked = managersLinked;
                this.countUsersLinked = usersLinked.length;
            },
            dataUsersTransform(data) {
                const users = [];

                data.forEach((e) => {
                    users.push({
                        id: e.user.id,
                        name: e.user.name,
                        role: e.userRole
                    });
                });

                return users;
            },
            sincronizeUsers() {
                const users = [];

                this.usersDestination.forEach((e) => users.push(e.id));
                this.managersDestination.forEach((e) => users.push(e.id));

                this.group.users = users;
            },
            async serviceLoadingUsers() {
                await this.$service.http['company']
                    .getListUsersLinkedByRole('ALL')
                    .then((result) => {
                        const records = result.data.docs;

                        const usersTransformeds = this.dataUsersTransform(records);

                        this.dataUsersPopulate(usersTransformeds);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            async geralLoading() {
                this.$store.commit('setGlobalLoading', true);

                await this.serviceLoadingUsers();

                this.$store.commit('setGlobalLoading', false);
            },
            onChangeListUsers(data) {
                const { source, destination } = data;

                this.usersLinked = source;
                this.usersDestination = destination;
            },
            onChangeListManagers(data) {
                const { source, destination } = data;

                this.managersLinked = source;
                this.managersDestination = destination;
            },
            goToAfterSubmitForm() {
                this.$router.push({ name: 'groupsCompany' });
            },
            validateSubmitForm() {
                const snack = (message) => this.activeSnack(false, message);

                const validateGroupName = (name) => {
                    if (!name) {
                        snack('Preencha o campo nome do grupo!');
                        return true;
                    }

                    if (name.length < 6) {
                        snack('O nome do grupo deve conter no mínimo 6 caracteres!');
                        return true;
                    }

                    if (name.length > 128) {
                        snack('O nome do grupo deve conter até 128 caracteres!');
                        return true;
                    }

                    return false;
                };

                const validateGroupUserLinked = (usersLinked) => {
                    if (!usersLinked.length) {
                        snack('O grupo deve conter pelo menos um aluno vinculado!');
                        return true;
                    }

                    return false;
                };

                const errors = [
                    validateGroupUserLinked(this.usersDestination),
                    validateGroupName(this.group.name)
                ];

                return !errors.some((e) => e);
            },
            async serviceUpdateGroup() {
                const groupId = this.groupStore.id;

                await this.$service.http['company']
                    .updateGroup(groupId, this.group)
                    .then((_) => {
                        this.activeSnack(true, 'Grupo atualizado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 3000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    });
            },
            async serviceCreateGroup() {
                await this.$service.http['company']
                    .createGroup(this.group)
                    .then((_) => {
                        this.activeSnack(true, 'Grupo criado!');

                        setTimeout(() => {
                            this.goToAfterSubmitForm();
                        }, 3000);
                    })
                    .catch((e) => {
                        console.error(e);

                        this.activeSnack(
                            false,
                            'Ocorreu um erro no processamento do endpoint!'
                        );
                    });
            },
            async submitForm() {
                if (!this.validateSubmitForm()) return;

                this.$store.commit('setGlobalLoading', true);

                this.formSubmited = true;

                if (this.groupStore.isEditionMode) {
                    await this.serviceUpdateGroup();
                } else {
                    await this.serviceCreateGroup();
                }

                this.formSubmited = false;

                this.$store.commit('setGlobalLoading', false);
            }
        }
    };
</script>

<style scoped>
    .sector {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .sector .form .form-head .head-title {
        font-size: 30px;
    }

    .sector .form .form-fields {
        margin-top: 20px;
    }

    .sector .form .form-fields .field-box-managers {
        margin-top: 40px;
    }

    .sector .form .form-fields .field-box-submit {
        margin-top: 30px;
    }

    .sector .form .form-fields .field-box .box-title {
        margin-bottom: 10px;
    }
</style>
