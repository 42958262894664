<template>
    <div class="list-box-wrapper">
        <div class="list-box-item">
            <div class="search-box">
                <input v-model="searchSource" type="text" placeholder="Buscar" />
                <div
                    v-if="searchSource"
                    class="clear-search"
                    title="Limpar busca"
                    @click="searchSource = ''">
                    &times;
                </div>
            </div>
            <div class="users-legend">Disponíveis</div>
            <ul class="list-box">
                <li
                    v-for="(item, key) in source
                        .map((item, inx) => ({ inx, ...item }))
                        .filter((item) =>
                            item[label in item ? label : 'label']
                                .toLowerCase()
                                .includes(searchSource.toLowerCase())
                        )"
                    v-bind:key="key"
                    :class="'list-item' + (item.selected ? ' active' : '')"
                    @click="selectSource(searchSource ? item.inx : key)">
                    {{ item[label in item ? label : 'label'] }}
                </li>
                <li
                    v-if="
                        source.filter((item) =>
                            item[label in item ? label : 'label']
                                .toLowerCase()
                                .includes(searchSource.toLowerCase())
                        ).length == 0 && source.length
                    "
                    class="list-item">
                    No results found
                </li>
            </ul>
            <div class="bulk-action">
                <div class="select-all" @click="selectAllSource">Selecionar todos</div>
                <div class="deselect-all" @click="deSelectAllSource">Nenhum</div>
            </div>
        </div>
        <div class="actions">
            <div class="btn-action" @click="moveDestination">
                <svg height="18" viewBox="0 0 256 512">
                    <path
                        fill="#ffffff"
                        d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
                </svg>
            </div>
            <div class="btn-action" @click="moveAllDestination">
                <svg height="18" viewBox="0 0 448 512">
                    <path
                        fill="#ffffff"
                        d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34zm192-34l-136-136c-9.4-9.4-24.6-9.4-33.9 0l-22.6 22.6c-9.4 9.4-9.4 24.6 0 33.9l96.4 96.4-96.4 96.4c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l136-136c9.4-9.2 9.4-24.4 0-33.8z" />
                </svg>
            </div>
            <div class="btn-action" @click="moveSource">
                <svg height="18" viewBox="0 0 256 512">
                    <path
                        fill="#ffffff"
                        d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                </svg>
            </div>
            <div class="btn-action" @click="moveAllSource">
                <svg height="18" viewBox="0 0 448 512">
                    <path
                        fill="#ffffff"
                        d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z" />
                </svg>
            </div>
        </div>
        <div class="list-box-item">
            <div class="search-box">
                <input v-model="searchDestination" type="text" placeholder="Buscar" />
                <div
                    v-if="searchDestination"
                    class="clear-search"
                    title="Limpar busca"
                    @click="searchDestination = ''">
                    &times;
                </div>
            </div>
            <div class="users-sel-legend">Selecionados</div>
            <ul class="list-group list-group-flush border rounded list-box">
                <li
                    v-for="(item, key) in destination
                        .map((item, inx) => ({ inx, ...item }))
                        .filter((item) =>
                            item[label in item ? label : 'label']
                                .toLowerCase()
                                .includes(searchDestination.toLowerCase())
                        )"
                    v-bind:key="key"
                    :class="'list-item' + (item.selected ? ' active' : '')"
                    @click="selectDestination(searchDestination ? item.inx : key)">
                    {{ item[label in item ? label : 'label'] }}
                </li>
                <li
                    v-if="
                        destination.filter((item) =>
                            item[label in item ? label : 'label']
                                .toLowerCase()
                                .includes(searchDestination.toLowerCase())
                        ).length == 0 && destination.length
                    "
                    class="list-item">
                    No results found
                </li>
            </ul>
            <div class="bulk-action">
                <div class="select-all" @click="selectAllDestination">
                    Selecionar todos
                </div>
                <div class="deselect-all" @click="deSelectAllDestination">Nenhum</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            source: Array,
            destination: Array,
            label: String
        },
        data: function () {
            return {
                searchSource: '',
                searchDestination: ''
            };
        },
        methods: {
            moveDestination: function () {
                let selected = this.source.filter((f) => f.selected);
                if (!selected.length) return;
                selected = selected.map((item) => ({
                    ...item,
                    selected: false
                }));
                let destination = [...selected, ...this.destination];
                let source = this.source.filter((f) => !f.selected);
                this.searchSource = '';
                this.searchDestination = '';
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            moveSource: function () {
                let selected = this.destination.filter((f) => f.selected);
                if (!selected.length) return;
                selected = selected.map((item) => ({
                    ...item,
                    selected: false
                }));
                let source = [...selected, ...this.source];
                let destination = this.destination.filter((f) => !f.selected);
                this.searchSource = '';
                this.searchDestination = '';
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            moveAllDestination: function () {
                let destination = [
                    ...this.source.map((item) => ({ ...item, selected: false })),
                    ...this.destination
                ];
                let source = [];
                this.searchSource = '';
                this.searchDestination = '';
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            moveAllSource: function () {
                let source = [
                    ...this.destination.map((item) => ({ ...item, selected: false })),
                    ...this.source
                ];
                let destination = [];
                this.searchSource = '';
                this.searchDestination = '';
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            selectDestination: function (key) {
                let source = this.source;
                let destination = this.destination.map((i, k) => {
                    if (k === key) {
                        i.selected = !i.selected;
                    }
                    return i;
                });
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            selectSource: function (key) {
                let destination = this.destination;
                let source = this.source.map((i, k) => {
                    if (k === key) {
                        i.selected = !i.selected;
                    }
                    return i;
                });
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            selectAllSource: function () {
                let source = this.source.map((item) => ({ ...item, selected: true }));
                let destination = this.destination;
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            deSelectAllSource: function () {
                let source = this.source.map((item) => ({ ...item, selected: false }));
                let destination = this.destination;
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            selectAllDestination: function () {
                let destination = this.destination.map((item) => ({
                    ...item,
                    selected: true
                }));
                let source = this.source;
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            },
            deSelectAllDestination: function () {
                let destination = this.destination.map((item) => ({
                    ...item,
                    selected: false
                }));
                let source = this.source;
                this.$emit('onChangeList', {
                    source,
                    destination
                });
            }
        }
    };
</script>

<style scoped>
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    .list-box-wrapper {
        font-family: sans-serif;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .list-box-wrapper > div {
        flex: 1;
    }

    /* .list-box-wrapper .list-box-item {
    }*/

    .users-legend {
        background-color: #d0d0d0;
        color: #000;
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
        text-align: center;
    }

    .users-sel-legend {
        background-color: #43c649;
        color: #fff;
        font-size: 14px;
        line-height: 30px;
        font-weight: bold;
        text-align: center;
    }

    .list-box-wrapper .list-box-item .search-box {
        /* border-bottom: solid 1px #cccccc; */
        background-color: #fff; /* aqui */
        position: relative;
        -webkit-border-top-left-radius: 10px;
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topleft: 10px;
        -moz-border-radius-topright: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .list-box-wrapper .list-box-item .search-box input {
        border: none;
        width: 100%;
        padding: 0.5rem 1rem;
    }

    .list-box-wrapper .list-box-item .search-box .clear-search {
        position: absolute;
        padding: 0.5rem;
        right: 0;
        top: 0;
        cursor: pointer;
        font-weight: bold;
        color: #7504c0;
    }

    .list-box-wrapper .list-box-item .list-box {
        height: 250px;
        overflow: auto;
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: #f0f0f0; /* aqui */
    }

    .list-box-wrapper .list-box-item .list-box .list-item {
        margin: 5px;
        padding: 0.5rem 1rem;
        /* border-bottom: solid 1px #cccccc; */
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        background-color: #e0e0e0;
        font-size: 14px;
        cursor: pointer;
    }

    .list-box-wrapper .list-box-item .list-box .list-item.active {
        background-color: #b9feb5; /* aqui */
        color: #000;
    }

    .list-box-wrapper .list-box-item .bulk-action {
        display: flex;
        /* border-top: solid 1px #cccccc; */
        text-align: center;
    }

    .list-box-wrapper .list-box-item .bulk-action .select-all {
        width: 100%;
        padding: 0.8rem;
        background-color: #7b009c; /* aqui */
        color: #fff;
        cursor: pointer; /* aqui */
        -webkit-border-bottom-left-radius: 10px;
        -moz-border-radius-bottomleft: 10px;
        border-bottom-left-radius: 10px;
    }

    .list-box-wrapper .list-box-item .bulk-action .deselect-all {
        width: 100%;
        padding: 0.8rem;
        background-color: #b9b9b9;
        color: rgb(137, 137, 137);
        cursor: pointer; /* aqui */
        -webkit-border-bottom-right-radius: 10px;
        -moz-border-radius-bottomright: 10px;
        border-bottom-right-radius: 10px;
    }

    .list-box-wrapper .actions {
        flex-grow: 0;
        padding: 0 1rem;
    }

    .list-box-wrapper .actions .btn-action {
        margin-bottom: 0.5rem;
    }

    .btn-action {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        /* vertical-align: middle; */
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        display: block;
        width: 100%;
        color: #fff;
        background-color: #7b009c;
        border-color: #7b009c;
        cursor: pointer;
    }

    .btn-action svg {
        vertical-align: middle;
    }
</style>
